<template lang="html">
  <div class="mt-5">
    <CardLead></CardLead>
  </div>
</template>
 
<script lang="js">
import CardLead from "@/views/dashboard/leads/CardLead";
export default  {
  name: 'Leads',
  props: {},
  components: {
    CardLead
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
  }
}
</script>

<style>
.b-sidebar{
  width: 600px;
}
.card-congratulation-medal {
  height: 100%;
  max-height: 100%;
}
.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner-leads .card-body {
  display: flex;
  justify-content: flex-start;
}
.icon-leads {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367F0;
  padding: 5px;
  margin-right: 10px;
  border-radius: 10px;
}
.objetfit img{
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.header-card-creator {
  height: 188px;
}
</style>