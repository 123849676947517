<template lang="html">
  <div>
    <b-row>
      <b-col cols="12">
          <b-card class="banner-leads">
              <feather-icon
              icon="ZapIcon"
              size="40"
              class="icon-leads"
              />
              <h1 class="ml-2">BrandMe Leads</h1>
          </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
        <b-col
        cols="12"
        md="6"
        lg="4"
        v-for="item in items"
        :key="item.id || item.title" >
            <b-card
            :img-src="item.cover"
            :img-alt="item.title"
            img-top
            class="card-profile objetfit">
                <div class="profile-image-wrapper">
                    <div class="profile-image p-0">
                        <span class="b-avatar badge-light rounded-circle" style="width: 114px; height: 114px;">
                            <span class="b-avatar-img"><img :src="item.avatar" alt="avatar"></span>
                        </span>
                    </div>
                </div>

                <h3>{{ item.title }}</h3>
                <div class="w-100 d-flex justify-content-center ">
                  <b-alert
                  show
                  :variant="getVariant(item.participation)"
                  class="w-auto px-1 "
                  >
                    {{ $t(utils_leads.getAlertText(item.participation)) }}
                  </b-alert>
                </div>


                <hr class="mb-2">
                <div class="d-flex justify-content-center">
                  <div>
                    <b-alert
                    show
                    :variant="getVariant(item.participation)"
                    class="w-auto px-1 "
                    v-if="item.participation"
                    >
                      {{$t(utils_leads.getAlertText(item.participation))}}
                    </b-alert>
                    <b-button v-if="item.status === 'enabled'" @click="lead_campaign(item.slug_name)" class="btn-blue-brandme"
                              :disabled="checkParticipation(item.participation)"
                    >{{$t(utils_leads.getButtonText(item.participation))}}</b-button>
                    <b-button v-if="item.status === 'close'" class="btn-blue-brandme" disabled> {{ $t(utils_leads.getButtonText(item.partipation))}}</b-button>
                  </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
  </div>
</template>

<script lang="js">
import {
  BAlert, BCard, BRow, BCol, BButton
} from 'bootstrap-vue'
import service_leads from '@/services/leads'
import utils from '@/libs/utils'
import utils_leads from '@/libs/leads'
export default  {
  name: 'CardLead',
  props: {},
  components: {
    BAlert,
    BCard,
    BRow,
    BButton,
    BCol

  },
  mounted() {
  },
  beforeCreate() {
    service_leads.getLeads().then(response => {
      this.items = response.results.map(function(item) {
        return {
          id: item.uuid,
          slug_name: item.slug_name,
          cover: item.header !== null ? utils.getAssetUrl(item.header) : require('@/assets/images/leads/grandmasters/cover_lead.png'),
          avatar: item.logo !== null ? utils.getAssetUrl(item.logo) : require('@/assets/images/leads/grandmasters/master_logo.png'),
          title: item.name,
          href: 'modal',
          description: item.description,
          status: item.status,
          participation : item.participation
        }
      }); 
      
      this.items.push({
        id: 2,
        slug_name: 'e66a6435-a0ce-4567-a198-d1b0ebc88973',
        cover: 'https://i.blogs.es/2b545d/rappi-1/1366_2000.jpg',
        avatar: 'https://i.blogs.es/2b545d/rappi-1/1366_2000.jpg',
        title: 'Rappi Select',
        href: 'none',
        description: 'Rappi select description',
        status: 'close',
        participation : null
      });
    });
  },
  data() {
    return {
      items: [],
      utils_leads
    }
  },
  methods: {
    getLink(slug_name) {
      return `lead/${slug_name}`;
    },
    lead_campaign(slug_name) {
      this.$router.push({ path: `lead/${slug_name}` });
    },
    getVariant(participation) {
      if (participation === null || participation.status === "approved_lead")
        return "success";
      return "warning";
    },
    checkParticipation(participation) {
      return participation !== null && (participation.status === 'rejected_lead' || participation.status === 'pending_lead');
    }
  },

}
</script>

<style>
  .banner-leads .card-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .icon-leads {
    background-color: rgba(115, 103, 240, 0.12);
    color: #7367F0;
    padding: 5px;
    margin-right: 10px;
    border-radius: 10px;
  }
  .btn-blue-brandme {
    background: rgb(31,86,255);
    background: radial-gradient(circle, rgba(31,86,255,1) 0%, rgba(0,63,255,1) 45%);
    border-color: rgb(31,86,255) !important;
  }
</style>
